import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { eligibleCountries, manualMapping } from '../../helper/googlePlaces';
import { ErrorMessage } from '../../helper/errorMapper';
import clsx from 'clsx';
import CSS from './LocationInput.module.css';

type LocationInputProps = {
  onChange: (newValue: any) => void;
  value?: string;
  error?: ErrorMessage | null;
}

const LocationInput = ({ error, value, onChange }: LocationInputProps) => {
  const { t } = useTranslation();
  const onPlaceSelected = (place: any) => {
    if (place.formatted_address && place.address_components) {
      onChange(place);
    } else {
      onChange(manualMapping(place.name));
    }
  };
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: onPlaceSelected,
    language: 'NL',
    options: {
      types: ['geocode'],
      componentRestrictions: { country: eligibleCountries }
    }
  });

  const onBlur = (e: any) => {
    if (e.currentTarget.value === value) {
      return;
    }
    onChange(manualMapping(e.currentTarget.value));
  };

  return (
    <input
      defaultValue={value}
      type={'text'}
      onBlur={onBlur}
      ref={ref as any}
      placeholder={t('Input.SelectLocation')}
      className={clsx(CSS.Input, error ? 'border-2 border-input-error' : 'border border-input')}
    />
  );
};

export default LocationInput;
