import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import CSS from './DateInputButton.module.css';

type DateInputButtonProps = {
  hasError?: boolean;
  onClick?: MouseEventHandler;
  value?: string;
}

const DateInputButton = React.forwardRef<HTMLButtonElement, DateInputButtonProps>(({ hasError, onClick, value }, ref) => {
  return (
    <button
      type={'button'}
      onClick={onClick}
      ref={ref}
      className={clsx(CSS.Button, hasError ? 'border-2 border-input-error' : 'border border-input')}
    >
      {value}
    </button>
  );
});

export default DateInputButton;