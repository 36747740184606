import React from 'react';
import CSS from './LoadingDots.module.css';

const LoadingDots = () => {
  return (
    <div className={CSS.Container}>
      <span className={CSS.LoadingDot1}>.</span>
      <span className={CSS.LoadingDot2}>.</span>
      <span className={CSS.LoadingDot3}>.</span>
    </div>
  );
};

export default LoadingDots;