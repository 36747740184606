import React from 'react';
import CloseIcon from '../../icons/CloseIcon';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ChevronLeft from '../../icons/ChevronLeft';
import { useAppDispatch } from '../../app/hooks';
import { setWidgetOpen } from '../../features/ui/uiSlice';
import FilledStar from '../../icons/FilledStar';
import clsx from 'clsx';
import CSS from './WidgetHeader.module.css';
import { Chef } from '../../types/Chef';

type WidgetHeaderProps = {
  chef?: Chef;
}

const WidgetHeader = ({ chef }: WidgetHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isSuccess = matchPath('/success/*', location.pathname);
  const isHome = matchPath(location.pathname, '/');
  const isEnd = matchPath(location.pathname, '/details');

  const onBackClick = () => {
    if (!isHome && !isSuccess) {
      navigate(-1);
    } else {
      dispatch(setWidgetOpen(false));
    }
  };

  return (
    <div className={clsx(CSS.Container, !isEnd && CSS.LimitedWidthContainer)}>
      <div className={CSS.Wrapper}>
        <button onClick={onBackClick} className={CSS.BackButton}>
          {(isHome || isSuccess) ? <CloseIcon/> : <ChevronLeft/>}
        </button>
        <div className={CSS.TitleBar}>
          <Trans t={t}>
            <h1 className={CSS.Title}>{chef?.name}</h1>
            {chef && chef?.review_amount > 0 ?
              <>
                <div className={CSS.StarContainer}><FilledStar/></div>
                <h1>
                  <Trans i18nKey="Header.ChefScore"
                    values={{ score: chef?.review_score ?? 0, nrOfReviews: chef?.review_amount ?? 0 }}>
                    {'{{score}}'} uit {'{{nrOfReviews}}'}
                    <span className={CSS.PartialTitle}>reviews op
                      <a className={CSS.Link}
                        target={'_blank'} rel={'noopener noreferrer'} href={'https://www.chefstbl.com'}>
                                            CHEFSTBL
                      </a>
                    </span>
                  </Trans>
                </h1>
              </>
              : ''
            }
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default WidgetHeader;
