import { ReservationState } from '../features/reservation/reservationSlice';
import { Listing } from '../types/Listing';
import { addDays, format } from 'date-fns';

export const hasIncorrectNumberOfPeople = (listing: Listing, reservation: ReservationState) => {
  return reservation.numberOfPeople === null || reservation.numberOfPeople < listing.min_guests || reservation.numberOfPeople > listing.max_guests;
};

/**
 * Returns a new Date object if one is found within nrOfDaysAhead
 * @param blockedDates
 * @param nrOfDaysAhead
 */
export const getFirstAvailableDate = (blockedDates: string[], nrOfDaysAhead: number ): Date | null => {
  const now = new Date();
  return new Array(nrOfDaysAhead).fill(null)
    .reduce((acc, curr, index) => {
      // we have a date, stop execution
      if (acc) {
        return acc;
      }

      // create new date and format
      const newDate = addDays(now, index);
      const newDateString = format(newDate, 'yyyy-MM-dd');

      // check if new date is in blocked dates
      if (blockedDates.includes(newDateString)) {
        // if so, continue
        return acc;
      }

      return newDate;
    }, null);
};