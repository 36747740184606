import {Chef} from '../../types/Chef';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {format} from 'date-fns';
import {RootState} from '../../app/store';
import {ReservationState} from '../reservation/reservationSlice';
import {DetailsState} from '../details/detailsSlice';
import {eligibleCountriesAlpha3} from '../../helper/googlePlaces';
import {ReservationPostBody} from '../../types/Reservation';

export const chefApi = createApi({
    reducerPath: 'chefApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://widget-api.chefstbl.com/v1',
        prepareHeaders: (headers, {getState}) => {
            const {signature} = (getState() as RootState).reservation;

            if (!signature) {
                console.error('missing signature');
            } else {
                headers.set('X-Chefstbl-Signature', signature);
            }

            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json');

            return headers;
        }
    }),
    endpoints: (builder) => ({
        getChefById: builder.query<Chef, { chefId: string | number, chefLabel: string | number | null }>({
            query: ({chefId, chefLabel}) => {
                let path = `/chef/${chefId}`;
                if (chefLabel) {
                    path += `/label/${chefLabel}`;
                }

                return {
                    url: path,
                };
            }
        }),
        makeReservation: builder.mutation<any, { reservation: ReservationState; details: DetailsState }>({
            query: ({reservation, details}) => {
                const addressObject = {
                    street: details.location.street,
                    houseNumber: details.location.houseNumber,
                    addition: details.location.annex,
                    city: details.location.placeOfResidence,
                    zipcode: details.location.postCode,
                    country: eligibleCountriesAlpha3[details.location.countryOfResidence]
                };

                const body: ReservationPostBody = {
                    'testing': false, // todo dynamic
                    'guests': reservation.numberOfPeople ?? 0,
                    'date': `${format(reservation.date ?? 0, 'yyyy-MM-dd')}T${reservation.time}`,
                    'address': addressObject,
                    'booker': {
                        'first_name': details.firstName,
                        'last_name': details.lastName,
                        'email': details.email,
                        'phone': details.phoneNumber
                    },
                    'extras': reservation.extras
                        .filter((extra: any) => extra.amount > 0)
                        .map((extra: any) => ({
                            id: extra.extraId,
                            quantity: extra.amount
                        }))
                };

                if (details.receiveInvoice) {
                    body.vat_company_address = details.sameBillingAddress ? addressObject : {
                        street: details.companyLocation.street,
                        houseNumber: details.companyLocation.houseNumber,
                        addition: details.companyLocation.annex,
                        city: details.companyLocation.placeOfResidence,
                        zipcode: details.companyLocation.postCode,
                        country: eligibleCountriesAlpha3[details.companyLocation.countryOfResidence]
                    };
                }

                return {
                    url: `/reservation/chef/${reservation.chefId}/listing/${reservation.listingId}`,
                    method: 'POST',
                    body
                };
            }
        }),
        getPrice: builder.query<any, { reservation: ReservationState; details: DetailsState }>({
            query: ({reservation, details}) => {
                if (!reservation.date) {
                    return 'false';
                }

                return {
                    url: `/price/chef/${reservation.chefId}/listing/${reservation.listingId}`,
                    method: 'POST',
                    body: {
                        'guests': reservation.numberOfPeople,
                        'date': `${format(reservation.date, 'yyyy-MM-dd')}T${reservation.time}`,
                        'address': details.location.formattedAddress,
                        'extras': reservation.extras
                            .filter((extra: any) => extra.amount > 0)
                            .map((extra: any) => ({
                                id: extra.extraId,
                                quantity: extra.amount
                            }))
                    }
                };
            }
        }),
    }),
});

export const {useGetChefByIdQuery, useGetPriceQuery, useMakeReservationMutation} = chefApi;
