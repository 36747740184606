import React from 'react';
import CSS from './TextBlock.module.css';

export const TextBlockTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <h1 className={CSS.TextBlockTitle}>{children}</h1>
  );
};

export const TextBlockParagraph = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className={CSS.TextBlockParagraph}>{children}</div>
  );
};

export const TextBlock = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={CSS.TextBlock}>{children}</div>
  );
};

