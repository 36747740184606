export type ErrorMessage = {
    field: 'numberOfPeople' | 'listing' | 'date' | 'time' | 'location' | 'other';
} & (
    | { messageKey?: never; message: string } // either has message (unmapped error) or messageKey (mapped error)
    | { messageKey: string; message?: never }
    );

export const mapError = (error: any): ErrorMessage | null => {
    if (!error || !error.data || !error.data.message)
        return null;

    if (error.data?.errors) {
        const firstError = Object.entries(error.data.errors)?.[0];
        if (firstError) {
            const [field, messages] = firstError;
            return {
                field: field as any,
                message: (messages as string[])?.[0]
            };
        }
    }

    switch (error.data.message) {
        case 'Your location is outside the chef\'s work area':
            return {
                field: 'location',
                messageKey: 'Errors.LocationOutOfBounds'
            };
        case 'The given data was invalid.':
            return {
                field: 'location',
                messageKey: 'Errors.LocationOutOfBounds'
            };
    }

    return null;
};
