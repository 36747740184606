import React from 'react';
import clsx from 'clsx';
import CSS from './InputWrapper.module.css';

type InputWrapperProps = {
  children: React.ReactNode;
  dense?: boolean;
}

const InputWrapper = ({ dense, children }: InputWrapperProps) => {
  return (
    <div className={clsx(CSS.Container, dense && 'grid-cols-small-input-dense')}>
      {children}
    </div>
  );
};

export default InputWrapper;