import {ReservationState} from '../features/reservation/reservationSlice';
import {ErrorMessage} from './errorMapper';
import {DetailsState} from '../features/details/detailsSlice';

export const reservationIsValid = (reservation: ReservationState, details: DetailsState, step: 1 | 2 | 3): { isValid: boolean; errors: ErrorMessage[] } => {
    let isValid = true;
    const errors: ErrorMessage[] = [];

    if (step > 1) {
        if (reservation.numberOfPeople === null) {
            errors.push({field: 'numberOfPeople', messageKey: 'Errors.InvalidNrOfPeople'});
            isValid = false;
        }

        if (reservation.numberOfPeople && reservation.numberOfPeople < 0) {
            errors.push({field: 'numberOfPeople', messageKey: 'Errors.InvalidNrOfPeople'});
            isValid = false;
        }

        if (reservation.numberOfPeople && reservation.numberOfPeople > reservation.maxNumberOfPeople) {
            errors.push({field: 'numberOfPeople', messageKey: 'Errors.InvalidNrOfPeople'});
            isValid = false;
        }

        if (!reservation.date) {
            errors.push({field: 'date', messageKey: 'Errors.InvalidDate'});
            isValid = false;
        }
        if (!reservation.time) {
            errors.push({field: 'time', messageKey: 'Errors.InvalidTime'});
            isValid = false;
        }
        if (!details.location.formattedAddress) {
            errors.push({field: 'location', messageKey: 'Errors.InvalidLocation'});
            isValid = false;
        }
    }

    if (step === 3) {
        // todo could implement popover errors here
    }

    if (!reservation.listingId) {
        errors.push({field: 'listing', messageKey: 'Errors.InvalidListing'});
        isValid = false;
    }

    return {
        isValid,
        errors
    };
};
