import React, {useRef} from 'react';
import {Manager, Popper, Reference} from 'react-popper';
import {useTranslation} from 'react-i18next';
import {ErrorMessage} from '../../helper/errorMapper';
import CSS from './ErrorPopper.module.css';
import clsx from 'clsx';

type ErrorPopperProps = {
    children: React.ReactNode;
    errors?: ErrorMessage[];
    show: boolean;
}

const STATIC_MODIFIERS = [
    {
        name: 'preventOverflow',
        options: {
            altAxis: true
        }
    },
    {
        name: 'offset',
        options: {
            offset: [0, 16],
        },
    }
];

const ErrorPopper = ({children, show, errors = []}: ErrorPopperProps) => {
    const arrowRef = useRef(null);
    const {t} = useTranslation();

    return (
        <Manager>
            <Reference>
                {({ref}) => {
                    return React.Children.map(children, (child: any) => {
                        return React.cloneElement(child, {ref});
                    });
                }}
            </Reference>
            {show && errors.length > 0 &&
                <Popper placement={'top'}
                        modifiers={[
                            ...STATIC_MODIFIERS, {
                                name: 'arrow',
                                enabled: true,
                                options: {
                                    element: arrowRef.current
                                },
                            }]}
                >
                    {({ref, style, arrowProps}) => (
                        <div ref={ref} style={style}>
                            <div className={CSS.Container}>
                                De volgende fouten zijn opgetreden:
                                <ul>
                                    {errors.map((err: ErrorMessage) => (
                                        <li key={err.messageKey ?? err.message}>{err.messageKey ? t(err?.messageKey) : err.message}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className={CSS.TooltipContainer} ref={arrowProps.ref} style={arrowProps.style}>
                                <div className={clsx(CSS.TooltipArrow, 'rotate-45')}/>
                            </div>
                        </div>
                    )}
                </Popper>
            }
        </Manager>
    );
};

export default ErrorPopper;
