import React from 'react';
import { format } from 'date-fns';
import { Pricing } from '../../types/Pricing';
import PricingRow from '../PricingRow';
import PricingBlock from '../PricingBlock';
import { useTranslation } from 'react-i18next';
import { ReservationState } from '../../features/reservation/reservationSlice';
import CSS from './PricingTable.module.css';
import { Extra } from '../../types/Extra';

type PricingTableProps = {
  pricing?: Pricing;
  reservation: ReservationState;
}

const PricingTable = ({ reservation, pricing }: PricingTableProps) => {
  const { t } = useTranslation();
  return (
    <div className={CSS.Container}>
      <PricingBlock>
        <PricingRow label={t('Overview.Date')}
          value={reservation.date ? format(reservation.date, 'dd-MM-yyyy') : ''}/>
        <PricingRow label={t('Overview.Time')} value={reservation.time ?? ''}/>
        <PricingRow label={t('Overview.NumberOfPeople')} value={reservation.numberOfPeople}/>
        <PricingRow label={t('Overview.PricePerPerson')} value={pricing?.arrangements.price}/>
        <PricingRow label={t('Overview.Subtotal')} value={pricing?.arrangements.total}/>
      </PricingBlock>
      {pricing?.extras && pricing.extras.length > 0 &&
            <div>
              <h3 className={CSS.ExtrasTitle}>{t('Overview.Extras')}</h3>
              <PricingBlock>
                {pricing.extras.map((extra: Extra) => (
                  <PricingRow 
                    key={extra.id}
                    label={`${extra.quantity}x ${extra.title} * ${extra.price}`}
                    value={extra.total}
                  />
                ))}
                <PricingRow label={t('Overview.Subtotal')} value={pricing.total_extras}/>
              </PricingBlock>
            </div>

      }
      <PricingBlock show={!!pricing?.total_extras || !!pricing?.arrangements.total || !!pricing?.travel_costs}>
        <PricingRow label={t('Overview.PriceExtras')} value={pricing?.total_extras} hideIfNoValue/>
        <PricingRow label={t('Overview.PriceArrangements')} value={pricing?.arrangements.total}/>
        <PricingRow label={t('Overview.PriceTransport')} value={pricing?.travel_costs} hideIfNoValue/>
      </PricingBlock>
      <PricingBlock>
        <PricingRow label={t('Overview.PriceTotal')} value={pricing?.total} size={'large'}/>
      </PricingBlock>
    </div>
  );
};

export default PricingTable;