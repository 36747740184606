import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectReservation, setDate, setListing} from '../../features/reservation/reservationSlice';
import {useGetChefByIdQuery} from '../../features/chef/chefAPI';
import ListingCard from '../../components/ListingCard';
import {format} from 'date-fns';
import {useLocation, useNavigate} from 'react-router-dom';
import {reservationIsValid} from '../../helper/reservation';
import {Listing} from '../../types/Listing';
import ReservationDetails from '../../components/ReservationDetails';
import ErrorPopper from '../../components/ErrorPopper';
import Button from '../../components/Button';
import useTimeoutState from '../../hooks/useTimeoutState';
import {selectDetails} from '../../features/details/detailsSlice';
import CSS from './ListingSelection.module.css';
import ReactGA from "react-ga4";

const ListingSelection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const details = useAppSelector(selectDetails);
    const reservation = useAppSelector(selectReservation);
    const {isValid, errors} = reservationIsValid(reservation, details, 1);
    const [showErrors, setShowErrors] = useTimeoutState<boolean>(false);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const {
        data: chef,
    } = useGetChefByIdQuery({
        chefId: reservation.chefId,
        chefLabel: reservation.chefLabel,
    });

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: "/select-listing"});

        if (!reservation.listingId && chef?.listings && chef?.listings.length === 1) {
            dispatch(setListing(chef.listings?.[0]));
            navigate({
                ...location,
                pathname: '/listing',
            });
        }
    }, [navigate, location, dispatch, chef, reservation.listingId]);

    useEffect(() => {
        if (chef?.blocked_dates && reservation.date) {
            chef.blocked_dates.forEach((blockedDate) => {
                if (reservation.date) {
                    if (blockedDate === format(reservation.date, 'yyyy-MM-dd')) {
                        dispatch(setDate(null));
                    }
                }
            });
        }
    }, [dispatch, chef, reservation.date]);

    const handleListingClick = (listing: Listing) => () => {
        dispatch(setListing(listing));
    };

    const onContinueClick = () => {
        if (!isValid) {
            setShowErrors(true, {timeout: 3000});
        } else {
            navigate({
                ...location,
                pathname: '/listing',
            });
        }
    };

    const moreThanOneListing = chef?.listings && chef.listings.length > 1;

    return (
        <>
            <div className={CSS.Container}>
                <form className={CSS.Form}>
                    <ReservationDetails/>
                    {moreThanOneListing ?
                        <div className={CSS.ListingsContainer}>
                            <ul className={CSS.ListingList}>
                                {chef?.listings.map((listing) => (
                                    <li key={listing.id}>
                                        <div onClick={handleListingClick(listing)}>
                                            <ListingCard listing={listing} selected={listing.id === reservation.listingId}/>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div> :
                        <span className={CSS.NoSelectionFiller}>Vul hierboven uw reserveringsgegevens in. Klik daarna op "Verder"</span>
                    }
                </form>
            </div>
            <div className={CSS.FooterContainer}>
                <ErrorPopper errors={errors} show={showErrors}>
                    <Button onClick={onContinueClick}>
                        {t('Buttons.Continue')}
                    </Button>
                </ErrorPopper>
            </div>
        </>
    );
};

export default ListingSelection;
