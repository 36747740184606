import { Switch } from '@headlessui/react';
import React from 'react';
import { Extra } from '../../types/Extra';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { changeExtra, selectReservation } from '../../features/reservation/reservationSlice';
import clsx from 'clsx';
import CSS from './ExtraOnce.module.css';

type ExtraOnceProps = {
  extra: Extra;
}

const ExtraOnce = ({ extra }: ExtraOnceProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const reservation = useAppSelector(selectReservation);
  const value = reservation?.extras?.find(f => f.extraId === extra.id)?.amount ?? 0;
  const onChange = (value: boolean) => {
    if (value) {
      dispatch(changeExtra({ extra, amount: 1 }));
    } else {
      dispatch(changeExtra({ extra, amount: 0 }));
    }
  };

  const isApplied = value === 1;

  return (
    <div className={CSS.Container}>
      <div className={CSS.LabelContainer}>
        <span className={CSS.Label}>
          {extra.title}
          <span className={CSS.SubLabel}>
            {' ' + t('Listing.ExtrasPriceOneOff', { amount: extra.price })}
          </span>
        </span>
        <span className={CSS.Description}>{extra.description}</span>
      </div>
      <Switch checked={isApplied} onChange={onChange} className={clsx(CSS.Switch, isApplied ? 'bg-blue-600' : 'bg-gray-200')}>
        <span className={clsx(CSS.SwitchDot, isApplied ? 'translate-x-6' : 'translate-x-1')} />
      </Switch>
    </div>
  );
};

export default ExtraOnce;