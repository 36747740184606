import React, { ComponentPropsWithRef } from 'react';
import clsx from 'clsx';
import CSS from './Button.module.css';

type ButtonProps = {
  children: React.ReactNode;
  onClick: (e: MouseEvent) => void;
} & ComponentPropsWithRef<'button'>;

const Button = React.forwardRef(({ children, className, onClick, type = 'button' }: ButtonProps, ref: any) => {
  return (
    <button
      type={type}
      onClick={onClick}
      ref={ref}
      className={clsx(CSS.Button, className)}>
      {children}
    </button>
  );
});

export default Button;