import React from 'react';
import CSS from './PricingBlock.module.css';

type PricingBlockProps = {
  children: React.ReactNode;
  show?: boolean;
}

const PricingBlock = ({ show = true, children }: PricingBlockProps) => {
  if (!show)
    return null;

  return (
    <div className={CSS.Container}>
      {children}
    </div>
  );
};

export default PricingBlock;