import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import PricingTable from '../PricingTable';
import ChevronDown from '../../icons/ChevronDown';
import clsx from 'clsx';
import { ReservationState } from '../../features/reservation/reservationSlice';
import { Pricing } from '../../types/Pricing';
import { useTranslation } from 'react-i18next';
import CSS from './PricingPopover.module.css';

type PricingPopoverProps = {
  reservation: ReservationState;
  pricing: Pricing;
}

const PricingPopover = ({ reservation, pricing }: PricingPopoverProps) => {
  const { t } = useTranslation();

  return (
    <div className={CSS.Container}>
      <Popover>
        {({ open }) => (
          <>
            <Transition
              className={CSS.PanelWrapper}
              enter="transition-opacity duration-150 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150 ease-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel><PricingTable reservation={reservation} pricing={pricing}/></Popover.Panel>
            </Transition>
            <Popover.Button className={CSS.PanelButtonWrapper} disabled={!pricing?.total}>
              <div className={CSS.PanelButton}>
                <div className={CSS.PriceSummary}>
                  <span className={CSS.NumberOfGuestsLabel}>
                    {t('Listing.TotalPriceForNrOfPerson', { nrOfPersons: reservation.numberOfPeople })}
                  </span>
                  <span className={CSS.PriceLabel}>{pricing?.total ? pricing.total : '-'}</span>
                </div>
                <ChevronDown className={clsx('transition', open && 'transform rotate-180')}/>
              </div>
            </Popover.Button>
          </>
        )}
      </Popover>
    </div>
  );
};

export default PricingPopover;