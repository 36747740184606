import { configureStore } from '@reduxjs/toolkit';
import detailsReducer from '../features/details/detailsSlice';
import reservationReducer from '../features/reservation/reservationSlice';
import uiReducer from '../features/ui/uiSlice';
import { chefApi } from '../features/chef/chefAPI';

export const store = configureStore({
  reducer: {
    [chefApi.reducerPath]: chefApi.reducer,
    reservation: reservationReducer,
    ui: uiReducer,
    details: detailsReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(chefApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
