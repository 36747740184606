import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Extra } from '../../types/Extra';
import ExtraPerGuest from '../ExtraPerGuest';
import ExtraOnce from '../ExtraOnce';
import ChevronRight from '../../icons/ChevronRight';
import ChevronDown from '../../icons/ChevronDown';
import CSS from './ExtrasInput.module.css';

type ExtrasInputProps = {
  options: Extra[];
}

const ExtrasInput = ({ options }: ExtrasInputProps) => {
  const [expanded, setExpanded] = useState(false);

  const onExpanderClick = () => {
    setExpanded(expanded => !expanded);
  };

  return (
    <div>
      <button className={CSS.Button} onClick={onExpanderClick}>
        <span className={CSS.ButtonText}>Extras</span>
        {expanded ? <ChevronDown className={CSS.ButtonIcon}/> : <ChevronRight className={CSS.ButtonIcon}/>}
      </button>
      <Transition
        show={expanded}
        className={CSS.PanelWrapper}
        enter="transition-opacity duration-150 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={CSS.Panel}>
          {options.map((option) => {
            if (option.quantity_buttons) {
              return <ExtraPerGuest key={option.id} extra={option}/>;
            }
            return <ExtraOnce key={option.id} extra={option}/>;
          })}
        </div>
      </Transition>
    </div>
  );
};

export default ExtrasInput;
