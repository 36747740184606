import React from 'react';
import { Listing } from '../../types/Listing';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import { Transition } from '@headlessui/react';
import Slider from '../Slider';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { selectReservation } from '../../features/reservation/reservationSlice';
import { hasIncorrectNumberOfPeople } from '../../helper/listing';
import clsx from 'clsx';
import CSS from './ListingCard.module.css';

type ListingCardProps = {
  listing: Listing;
  selected: boolean;
}

const ListingCard = ({ listing, selected }: ListingCardProps) => {
  const { t } = useTranslation();
  const reservation = useAppSelector(selectReservation);

  return (
    <div className={CSS.Container}>
      <div className={CSS.HeaderContainer}>
        <Slider>
          {listing.images.map((img) => (
            <img
              key={img?.url}
              className={CSS.Image}
              alt={img?.title}
              src={img?.url}
            />
          ))}
        </Slider>
      </div>
      <div className={CSS.TitleContainer}>
        <span className={CSS.Title}>{listing.title}</span>
        <div className={clsx(CSS.Checkmark, selected && CSS.CheckmarkChecked)}>
          <Transition
            unmount={false}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150 opacity-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={selected ? '' : CSS.Hidden}>
              <CheckmarkIcon/>
            </div>
          </Transition>
        </div>
      </div>
      {hasIncorrectNumberOfPeople(listing, reservation) &&
            <div className={CSS.ErrorContainer}>
              {t('Errors.Listing.GuestsOutOfBounds', { min: listing.min_guests, max: listing.max_guests })}
            </div>
      }
    </div>
  );
};

export default ListingCard;