export const eligibleCountries = ['NL', 'BE', 'LU'];
export const eligibleCountriesAlpha3: {[ key: string]: string } = { 'NL': 'NLD', 'BE': 'BEL', 'LU': 'LUX' };

export const getStreet = (addressComponents: any[]) => {
  return addressComponents?.find(component => component.types.includes('route'))?.long_name ?? '';
};

export const getHouseNumber = (addressComponents: any[]) => {
  return addressComponents?.find(component => component.types.includes('street_number'))?.long_name ?? '';
};

export const getPostalCode = (addressComponents: any[]) => {
  return addressComponents?.find(component => component.types.includes('postal_code'))?.long_name ?? '';
};

export const getPlaceOfResidence = (addressComponents: any[]) => {
  return addressComponents?.find(component => component.types.includes('locality'))?.long_name ?? '';
};

export const getCountryOfResidence = (addressComponents: any[]) => {
  return addressComponents?.find(component => component.types.includes('country'))?.short_name ?? '';
};

/**
 * Maps the raw input from a user to address_components and formatted_address to stay in inline with
 * Google's Places API response
 * @param input string
 */
export const manualMapping = (input: string) => {
  const address_components: any[] = [];
  const formatted_address: string = input;
  if (input) {
    const inputParts = input.split(',');
    const inputPartsCount = inputParts.length;

    switch (inputPartsCount) {
      case 1:
        address_components.push({
          types: ['locality'],
          long_name: inputParts[0]
        });
        break;
      case 2:
        address_components.push({
          types: ['locality'],
          long_name: inputParts[0]
        });
        address_components.push({
          types: ['country'],
          long_name: inputParts[1]
        });
        break;
      case 3:
        address_components.push({
          types: ['route'],
          long_name: inputParts[0]
        });
        address_components.push({
          types: ['locality'],
          long_name: inputParts[1]
        });
        address_components.push({
          types: ['country'],
          long_name: inputParts[2]
        });
        break;
      case 4:
        address_components.push({
          types: ['route'],
          long_name: inputParts[0]
        });
        address_components.push({
          types: ['postal_code'],
          long_name: inputParts[1]
        });
        address_components.push({
          types: ['locality'],
          long_name: inputParts[2]
        });
        address_components.push({
          types: ['country'],
          long_name: inputParts[3]
        });
        break;
    }
  }

  return {
    address_components,
    formatted_address
  };
};
