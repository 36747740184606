import React, {useEffect} from 'react';
import {useGetChefByIdQuery} from '../../features/chef/chefAPI';
import {Transition} from '@headlessui/react';
import {Outlet, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectReservation, setChefDefaults, setChefId, setChefLabel, setSignature} from '../../features/reservation/reservationSlice';
import WidgetHeader from '../WidgetHeader';
import {selectWidgetOpen} from '../../features/ui/uiSlice';
import CSS from './WidgetWrapper.module.css';
import WidgetFooter from '../WidgetFooter';

type WidgetWrapperProps = {
    children?: React.ReactNode;
}

const WidgetWrapper = ({children}: WidgetWrapperProps) => {
    const [searchParams] = useSearchParams({chefId: ''});
    const dispatch = useAppDispatch();
    const reservation = useAppSelector(selectReservation);
    const widgetOpen = useAppSelector(selectWidgetOpen);

    useEffect(() => {
        if (reservation.chefId) {
            return;
        }

        const hasChefId = searchParams.has('chefId');
        const hasSignature = searchParams.has('signature');
        if (hasChefId && hasSignature) {
            const chefId = searchParams.get('chefId');
            if (chefId) {
                dispatch(setChefId(chefId));
            }

            const signature = searchParams.get('signature');
            if (signature) {
                dispatch(setSignature(signature));
            }

            if (searchParams.has('chefLabel')) {
                const chefLabel = searchParams.get('chefLabel');
                if (chefLabel) {
                    dispatch(setChefLabel(chefLabel));
                }
            }
        }
    }, [reservation.chefId, dispatch, searchParams]);

    const {
        data: chef,
        isLoading,
        isError,
        error
    } = useGetChefByIdQuery({
        chefId: reservation.chefId,
        chefLabel: reservation.chefLabel,
    }, {
        skip: !reservation.chefId
    });

    React.useEffect(() => {
        if (chef) {
            dispatch(setChefDefaults(chef));
        }
    }, [dispatch, chef]);

    if (!reservation.chefId)
        return null;

    if (!reservation.signature)
        return null;

    if (isLoading)
        return null;

    if (isError)
        return <span className={'text-red'}>{error}</span>;

    return (
        <div className={CSS.WidgetWrapper}>
            <Transition
                className={CSS.WidgetWrapperContainer}
                unmount={false}
                show={widgetOpen}
                enter="transition-opacity duration-500 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500 ease-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={CSS.Widget}>
                    <WidgetHeader chef={chef}/>
                    <Outlet/>
                    {children}
                    <WidgetFooter/>
                </div>
            </Transition>
        </div>
    );
};

export default WidgetWrapper;
