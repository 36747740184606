type WidgetPostMessage = {
  type: 'show' | 'hide' | 'request_resize';
  [key: string]: any;
};

export const postWidgetMessage = (message: WidgetPostMessage) => {
  window.parent.postMessage({
    source: 'chefstbl_widget',
    ...message,
  }, '*');
};