import React, { HTMLProps } from 'react';
import CSS from './InputWithLabel.module.css';

type InputWithLabelProps = {
  label: string;
  error?: string;
} & ({
  type?: 'input';
  inputProps: HTMLProps<HTMLInputElement>;
} | {
  type: 'textarea';
  inputProps: HTMLProps<HTMLTextAreaElement>;
})


const InputWithLabel = (props: InputWithLabelProps) => {
  return (
    <div>
      <label className={CSS.Label} htmlFor={props.inputProps?.id}>
        {props.label}
      </label>
      {props.type === 'textarea' ? <textarea {...props.inputProps}/> : <input {...props.inputProps}/>}
      {props.error && <p className={CSS.Error}>{props.error}</p>}
    </div>
  );
};

export default InputWithLabel;
