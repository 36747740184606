import CheckmarkIcon from '../../icons/CheckmarkIcon';
import React from 'react';
import clsx from 'clsx';
import CSS from './PricingRow.module.css';

type PricingRowProps = {
  size?: 'default' | 'large';
  includeLabelCheckmark?: boolean;
  label: string;
  value?: string | number | null;
  hideIfNoValue?: boolean;
}

const PricingRow = ({
  size = 'default',
  includeLabelCheckmark,
  label,
  hideIfNoValue = false,
  value
}: PricingRowProps) => {
  // do not render row if no value
  if (!value && hideIfNoValue)
    return null;

  return (
    <div className={clsx(CSS.Container, size === 'large' && CSS.ContainerLarge)}>
      <div className={clsx(CSS.LabelContainer, size === 'large' && CSS.LabelContainerLarge)}>
        {includeLabelCheckmark && <div className={CSS.Checkmark}><CheckmarkIcon/></div>}
        <span>{label}</span>
      </div>
      {!!value &&
            <span className={clsx(CSS.Value, size === 'large' && CSS.ValueLarge)}>
              {value}
            </span>
      }
    </div>
  );
};

export default PricingRow;
