import React from 'react';
import PlusIcon from '../../icons/PlusIcon';
import MinusIcon from '../../icons/MinusIcon';
import clsx from 'clsx';
import CSS from './CounterInput.module.css';

type CounterInputProps = {
  value: null | number;
  min?: number;
  max?: number;
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (value: number) => void;
  hasError?: boolean;
}

const CounterInput = ({
  min = 1,
  max,
  value,
  onIncrement,
  onDecrement,
  onChange,
  hasError
}: CounterInputProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onChange(parseInt(e.currentTarget.value || '0', 10));
  };

  return (
    <div className={clsx(CSS.Container, hasError ? 'border-2 border-input-error' : 'border border-input')}>
      <button
        disabled={value !== null && value <= min}
        type={'button'}
        onClick={onDecrement}
        className={CSS.ControlButton}>
        <MinusIcon/>
      </button>
      <input
        onChange={handleChange}
        type="number"
        className={CSS.Input}
        name="input_number"
        value={value === null ? '' : value}
      />
      <button
        disabled={max ? (value !== null && value >= max) : false}
        type={'button'}
        onClick={onIncrement}
        className={CSS.ControlButton}>
        <PlusIcon/>
      </button>
    </div>
  );
};

export default CounterInput;