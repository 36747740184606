import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {store} from './app/store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import WidgetWrapper from './components/WidgetWrapper';
import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import englishTranslations from './translations/en.json';
import dutchTranslations from './translations/nl.json';
import ListingSelection from './pages/listingSelection';
import ListingDetails from './pages/listingDetails';
import GuestDetails from './pages/guestDetails';
import Success from './pages/successPage';
import Button from './components/Button';
import {postWidgetMessage} from './helper/iframeInteraction';
import ReactGA from "react-ga4";

ReactGA.initialize('G-NXBZ9HRVM9');
ReactGA.send({hitType: "pageview", page: "/index"});

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: englishTranslations
        },
        nl: {
            translation: dutchTranslations
        }
    },
    lng: 'nl',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    },
    react: {
        transEmptyNodeValue: ''
    }
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path={'/toggle'} element={
                        <div>
                            <Button
                                className={'py-2 px-4 rounded-lg'}
                                onClick={() => postWidgetMessage({type: 'show'})}>
                                Boek nu
                            </Button>
                        </div>}
                    />
                    <Route path={'/'} element={<WidgetWrapper/>}>
                        <Route path={''} element={<ListingSelection/>}/>
                        <Route path={'listing'} element={<ListingDetails/>}/>
                        <Route path={'details'} element={<GuestDetails/>}/>
                        <Route path={'success/:bookingId'} element={<Success/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('chefstbl-widget-root')
);

serviceWorker.unregister();
