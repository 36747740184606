import React from 'react';
import {useTranslation} from 'react-i18next';
import {Pricing} from '../../types/Pricing';
import {useAppSelector} from '../../app/hooks';
import {selectReservation} from '../../features/reservation/reservationSlice';
import {useGetChefByIdQuery} from '../../features/chef/chefAPI';
import LoadingDots from '../LoadingDots';
import ListingImageHeader from '../ListingImageHeader';
import PricingTable from '../PricingTable';
import CSS from './PricingOverview.module.css';

type PricingOverviewProps = {
    pricing: Pricing;
}

const PricingOverview = ({pricing}: PricingOverviewProps) => {
    const {t} = useTranslation();
    const reservation = useAppSelector(selectReservation);

    const {
        data: chef,
        isLoading
    } = useGetChefByIdQuery({
        chefId: reservation.chefId,
        chefLabel: reservation.chefLabel,
    });

    const chosenListing = chef?.listings.find(listing => listing.id === reservation.listingId);

    if (isLoading || !chosenListing || !pricing) {
        return <LoadingDots/>;
    }

    return (
        <div className={CSS.Container}>
            <div>
                <ListingImageHeader listing={chosenListing}/>
            </div>
            <h2 className={CSS.Title}>{t('Overview.YourReservation')}</h2>
            <PricingTable pricing={pricing} reservation={reservation}/>
        </div>
    );
};

export default PricingOverview;
