import React from 'react';
import CSS from './WidgetFooter.module.css';

const WidgetFooter = () => {
  return (
    <div className={CSS.Container}>
      <div className={CSS.Wrapper}>
        <h2 className={CSS.Title}>
                    Powered by {' '}
          <a className={CSS.Link}
            target={'_blank'} rel={'noopener noreferrer'}
            href={'https://www.chefstbl.com'}>
                        CHEFSTBL
          </a>
        </h2>
      </div>
    </div>
  );
};

export default WidgetFooter;