import Button from '../../components/Button';
import React, {useEffect} from 'react';
import {useAppDispatch} from '../../app/hooks';
import {setWidgetOpen} from '../../features/ui/uiSlice';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import CircleCheckmarkIcon from '../../icons/CircleCheckmarkIcon';
import CSS from './SuccessPage.module.css';
import ReactGA from "react-ga4";

const Success = () => {
    const {bookingId} = useParams();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: "/success"});

        if (!bookingId) {
            navigate({
                ...location,
                pathname: '/',
            });
        }
        dispatch(setWidgetOpen(true));
    }, [dispatch, location, navigate, bookingId]);

    const onCloseClick = () => {
        dispatch(setWidgetOpen(false));
        setTimeout(() => {
            navigate({
                ...location,
                pathname: '/',
            });
        }, 500);
    };

    return (
        <>
            <div className={CSS.Container}>
                <span className={CSS.BookingId}>{t('Reservation.BookingId', {bookingId})}</span>
                <CircleCheckmarkIcon className={CSS.Checkmark}/>
                <h1 className={CSS.ThankYou}>{t('Reservation.ThankYou')}</h1>
                <h2 className={CSS.ThankYou2}>{t('Reservation.ThankYou2')}</h2>
            </div>
            <div className={CSS.FooterContainer}>
                <Button onClick={onCloseClick}>
                    {t('Buttons.Close')}
                </Button>
            </div>
        </>
    );
};

export default Success;
