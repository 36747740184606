import React, { HTMLProps } from 'react';
import CSS from './SelectWithLabel.module.css';

type SelectWithLabelProps = {
  label: string;
  selectProps: HTMLProps<HTMLSelectElement>;
  error?: string;
  children: React.ReactNode; // the options
}

const SelectWithLabel = ({ label, error, selectProps, children }: SelectWithLabelProps) => {
  return (
    <div>
      <label
        className={CSS.Label}
        htmlFor={selectProps?.id}>
        {label}
      </label>
      <select {...selectProps}>
        {children}
      </select>
      {error && <p className={CSS.Error}>{error}</p>}
    </div>
  );
};

export default SelectWithLabel;