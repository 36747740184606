import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectReservation, setDate} from '../../features/reservation/reservationSlice';
import 'react-datepicker/dist/react-datepicker.css';
import {useGetChefByIdQuery, useGetPriceQuery} from '../../features/chef/chefAPI';
import {useLocation, useNavigate} from 'react-router-dom';
import ErrorPopper from '../../components/ErrorPopper';
import {reservationIsValid} from '../../helper/reservation';
import ListingImageHeader from '../../components/ListingImageHeader';
import {TextBlock, TextBlockParagraph, TextBlockTitle} from '../../components/TextBlock';
import ReservationDetailsDense from '../../components/ReservationDetailsDense';
import Button from '../../components/Button';
import {ErrorMessage, mapError} from '../../helper/errorMapper';
import {getFirstAvailableDate} from '../../helper/listing';
import PricingPopover from '../../components/PricingPopover';
import {selectDetails} from '../../features/details/detailsSlice';
import CSS from './ListingDetails.module.css';
import ReactGA from "react-ga4";

const ListingDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const reservation = useAppSelector(selectReservation);
    const details = useAppSelector(selectDetails);
    const dispatch = useAppDispatch();
    const {isValid, errors} = reservationIsValid(reservation, details, 2);
    const {t} = useTranslation();

    const {
        data: chef,
        isLoading
    } = useGetChefByIdQuery({
        chefId: reservation.chefId,
        chefLabel: reservation.chefLabel,
    });

    const {
        currentData: pricing,
        error: pricingError,
        isFetching: pricingIsFetching
    } = useGetPriceQuery({reservation, details}, {
        skip: !isValid
    });

    const allErrors = [...errors, mapError(pricingError)].filter(f => !!f) as ErrorMessage[];

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: "/listing"});

        if (!reservation.listingId) {
            navigate({
                ...location,
                pathname: '/',
            });
        }
    }, [navigate, location, reservation.listingId]);

    // set first available date if found within 31 days
    useEffect(() => {
        if (!reservation.date && chef?.blocked_dates) {
            const firstAvailableDate = getFirstAvailableDate(chef?.blocked_dates, 31);
            if (firstAvailableDate) {
                dispatch(setDate(firstAvailableDate.getTime()));
            }
        }
    }, [dispatch, chef, reservation.date]);

    if (isLoading) {
        return null;
    }

    const [continueClicked, setContinueClicked] = useState(false);
    const onContinueClick = () => {
        setContinueClicked(true)

        if (!isValid || allErrors.length !== 0) {
            return;
        }

        if (pricingIsFetching) {
            return;
        }

        navigate({
            ...location,
            pathname: '/details',
        });
    };

    const chosenListing = chef?.listings.find(listing => listing.id === reservation.listingId);
    if (!chosenListing) {
        return null;
    }

    return (
        <>
            <div className={CSS.Container}>
                <ReservationDetailsDense showExtras errors={allErrors}/>
                <div className={CSS.ContentContainer}>
                    <ListingImageHeader listing={chosenListing}/>
                    <TextBlock>
                        <TextBlockTitle>{t('Listing.ExpectationsTitle', {chef: chef?.name})}</TextBlockTitle>
                        <TextBlockParagraph>
                            <div dangerouslySetInnerHTML={{__html: chosenListing?.description}}/>
                        </TextBlockParagraph>
                        {chosenListing?.description_custom &&
                            <TextBlockParagraph>
                                <div dangerouslySetInnerHTML={{__html: chosenListing?.description_custom}}/>
                            </TextBlockParagraph>
                        }
                        <TextBlockParagraph>
                            <ul className={CSS.List}>
                                {chosenListing.what_is_included.map((item) => (
                                    <li key={item}>{item}</li>
                                ))}
                            </ul>
                        </TextBlockParagraph>
                    </TextBlock>
                    <TextBlock>
                        <TextBlockTitle>{t('Listing.WhatYouShouldDoTitle')}</TextBlockTitle>
                        <TextBlockParagraph>
                            <ul className={CSS.List}>
                                {chosenListing.booker_action_items.map((item) => (
                                    <li key={item}>{item}</li>
                                ))}
                            </ul>
                        </TextBlockParagraph>
                        <TextBlockParagraph>
                            <span className={CSS.BookableAsExtra}>{t('Listing.BookableAsExtraNote')}</span>
                        </TextBlockParagraph>
                    </TextBlock>
                    {chosenListing?.features?.listing_feature?.length > 0 &&
                        <TextBlock>
                            <TextBlockTitle>{t('Listing.CanTakeIntoAccount')}</TextBlockTitle>
                            <TextBlockParagraph>
                        <span
                            dangerouslySetInnerHTML={{__html: chosenListing?.features?.listing_feature?.join(', ')}}/>
                            </TextBlockParagraph>
                        </TextBlock>
                    }
                    {chosenListing?.features?.specialization?.length > 0 &&
                        <TextBlock>
                            <TextBlockTitle>{t('Listing.SpecializedIn')}</TextBlockTitle>
                            <TextBlockParagraph>
                        <span
                            dangerouslySetInnerHTML={{__html: chosenListing?.features?.specialization?.join(', ')}}/>
                            </TextBlockParagraph>
                        </TextBlock>
                    }
                    {chosenListing?.example_menu?.length > 0 &&
                        <TextBlock>
                            <TextBlockTitle>{t('Listing.ExampleMenu')}</TextBlockTitle>
                            <TextBlockParagraph>
                                <ul className={CSS.List}>
                                    {chosenListing?.example_menu.map((menuItem) => (
                                        <li key={menuItem}>{menuItem}</li>
                                    ))}
                                </ul>
                            </TextBlockParagraph>
                        </TextBlock>
                    }
                </div>
            </div>
            <div className={CSS.FooterContainer}>
                <div className={CSS.FooterWrapper}>
                    <PricingPopover reservation={reservation} pricing={pricing}/>
                    <ErrorPopper errors={allErrors} show={allErrors.length > 0 && continueClicked}>
                        <div className={CSS.ContinueButtonContainer}>
                            <Button onClick={onContinueClick}>
                                {t('Buttons.Continue')}
                            </Button>
                        </div>
                    </ErrorPopper>
                </div>
            </div>
        </>
    );
};
export default ListingDetails;
