import React, {ChangeEvent, ChangeEventHandler, FormEvent, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectReservation} from '../../features/reservation/reservationSlice';
import {selectIsCompany, setDetailsField, selectDetails} from '../../features/details/detailsSlice';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGetPriceQuery, useMakeReservationMutation} from '../../features/chef/chefAPI';
import PricingOverview from '../../components/PricingOverview';
import {reservationIsValid} from '../../helper/reservation';
import Button from '../../components/Button';
import InputWithLabel from '../../components/InputWithLabel';
import CheckboxInput from '../../components/CheckboxInput';
import useDebounce from '../../hooks/useDebounce';
import {postWidgetMessage} from '../../helper/iframeInteraction';
import CSS from './GuestDetails.module.css';
import LabelCSS from '../../components/InputWithLabel/InputWithLabel.module.css';
import LocationFields from '../../components/LocationFields';
import ErrorPopper from '../../components/ErrorPopper';

import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import PhoneNL from 'react-phone-number-input/locale/nl.json'
import useTimeoutState from "../../hooks/useTimeoutState";
import ReactGA from "react-ga4";

const GuestDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const reservation = useAppSelector(selectReservation);
    const details = useAppSelector(selectDetails);
    const [makeReservation] = useMakeReservationMutation();
    const formRef = useRef<HTMLFormElement>(null);
    const {isValid, errors} = reservationIsValid(reservation, details, 3);
    const [showErrors, setShowErrors] = useTimeoutState<boolean>(false);
    const isCompany = useAppSelector(selectIsCompany);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: "/reservation"});

        // Interact with the parent iframe to increase the max width on mount of this page component
        postWidgetMessage({
            type: 'request_resize',
            width: '780px'
        });

        return () => {
            // Interact with the parent iframe to decrease the max width on unmount of this page component
            postWidgetMessage({
                type: 'request_resize'
            });
        };
    }, []);

    useEffect(() => {
        // Check that everything is fine until this step
        const {isValid} = reservationIsValid(reservation, details, 2);
        if (!isValid) {
            navigate({
                ...location,
                pathname: '/',
            });
        }
    }, [navigate, location, isValid]);

    // Debounce for user details
    const debouncedReservation = useDebounce(reservation, 500);
    const debouncedDetails = useDebounce(details, 500);

    const {
        data: pricing
    } = useGetPriceQuery({
        reservation: debouncedReservation,
        details: debouncedDetails
    }, {
        skip: !isValid
    });

    /**
     * any typing here is HTMLInputElement or HTMLTextAreaElement
     */
    const handleDetailsChange: ChangeEventHandler<any> = (e) => {
        dispatch(setDetailsField({fieldName: e.currentTarget.name, value: e.currentTarget.value}));
    };

    const handleDetailsBlur: ChangeEventHandler<any> = (e) => {
        dispatch(setDetailsField({fieldName: e.currentTarget.name, value: e.currentTarget.value.trim()}));
    };

    const handleReceiveInvoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setDetailsField({fieldName: e.currentTarget.name, value: e.currentTarget.checked}));
    };

    const onMakeReservationClick = () => {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            if (!isValid) {
                setShowErrors(true, {timeout: 3000});
                formRef.current.reportValidity();
            } else {
                formRef.current.dispatchEvent(
                    new Event('submit', {cancelable: true, bubbles: true})
                );
            }
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            const resp = await makeReservation({reservation, details}).unwrap();

            const {id: bookingId} = resp;

            navigate({
                ...location,
                pathname: `/success/${bookingId}`,
            });
        } catch (exception) {
            // todo translation and better way of showing error
            alert('Er is een fout opgetreden');

            console.error('Reservation failed', exception);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={CSS.Form} ref={formRef}>
                <div className={CSS.Container}>
                    <div className={CSS.Wrapper}>
                        <div className={CSS.InputsContainer}>
                            <div className={CSS.Inputs}>
                                <InputWithLabel
                                    type={'input'}
                                    label={t('Reservation.FirstName')}
                                    inputProps={{
                                        id: 'firstName',
                                        name: 'firstName',
                                        type: 'text',
                                        autoComplete: 'firstName',
                                        required: true,
                                        className: CSS.Input,
                                        onChange: handleDetailsChange,
                                        onBlur: handleDetailsBlur,
                                        value: details.firstName
                                    }}
                                />
                                <InputWithLabel
                                    type={'input'}
                                    label={t('Reservation.LastName')}
                                    inputProps={{
                                        id: 'lastName',
                                        name: 'lastName',
                                        type: 'text',
                                        autoComplete: 'lastName',
                                        required: true,
                                        className: CSS.Input,
                                        onChange: handleDetailsChange,
                                        onBlur: handleDetailsBlur,
                                        value: details.lastName
                                    }}
                                />

                                <InputWithLabel
                                    label={t('Reservation.Email')}
                                    inputProps={{
                                        id: 'email',
                                        name: 'email',
                                        type: 'email',
                                        autoComplete: 'email',
                                        required: true,
                                        className: CSS.Input,
                                        onChange: handleDetailsChange,
                                        onBlur: handleDetailsBlur,
                                        value: details.email
                                    }}
                                />

                                <div>
                                    <label className={LabelCSS.Label} htmlFor={'phoneNumber'}>
                                        {t('Reservation.PhoneNumber')}
                                    </label>

                                    <PhoneInput
                                        labels={PhoneNL}
                                        // value={details.phoneNumber} bug because of changing + to 00
                                        onChange={phone => {
                                            if (phone && isValidPhoneNumber(phone)) {
                                                phone = phone.replace(/\+/g, '00')
                                                dispatch(setDetailsField({fieldName: 'phoneNumber', value: phone}))
                                            }
                                        }}
                                        defaultCountry="NL"
                                        id={'phoneNumber'}
                                        name={'phoneNumber'}
                                        type={'tel'}
                                        autoComplete={'phoneNumber'}
                                        required={true}
                                        smartCaret={true}
                                    />
                                </div>
                            </div>
                            <LocationFields/>
                            <InputWithLabel
                                type={'textarea'}
                                label={t('Reservation.NotesLabel')}
                                inputProps={{
                                    id: 'notes',
                                    name: 'notes',
                                    placeholder: t('Reservation.Notes'),
                                    className: CSS.Input,
                                    onChange: handleDetailsChange,
                                    onBlur: handleDetailsBlur
                                }}
                            />
                            <CheckboxInput
                                name={'receiveInvoice'}
                                label={t('Reservation.ReceiveInvoice')}
                                checked={details.receiveInvoice}
                                onChange={handleReceiveInvoiceChange}
                            />
                            {isCompany &&
                                <>
                                    <InputWithLabel
                                        type={'input'}
                                        label={t('Reservation.CompanyName')}
                                        inputProps={{
                                            id: 'companyName',
                                            name: 'companyName',
                                            type: 'text',
                                            autoComplete: 'companyName',
                                            required: true,
                                            className: CSS.Input,
                                            placeholder: t('Reservation.CompanyName'),
                                            onChange: handleDetailsChange,
                                            onBlur: handleDetailsBlur,
                                            value: details.companyName
                                        }}
                                    />
                                    <LocationFields isCompany/>
                                </>}
                        </div>
                    </div>
                    <div className={CSS.PricingOverviewContainer}>
                        <PricingOverview pricing={pricing}/>
                    </div>
                </div>
                <div className={CSS.ButtonContainer}>
                    <ErrorPopper errors={errors} show={showErrors}>
                        <Button onClick={onMakeReservationClick}>
                            {t('Buttons.RequestReservation')}
                        </Button>
                    </ErrorPopper>
                </div>
            </form>
        </>
    );
};
export default GuestDetails;
