import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {ReservationState, selectReservation} from '../../features/reservation/reservationSlice';
import {useTranslation} from 'react-i18next';
import {useGetChefByIdQuery} from '../../features/chef/chefAPI';
import 'react-datepicker/dist/react-datepicker.css';
import ExtrasInput from '../ExtrasInput';
import {useAppSelector} from '../../app/hooks';
import {Transition} from '@headlessui/react';
import ChevronDown from '../../icons/ChevronDown';
import ReservationDetails from '../ReservationDetails';
import ChevronRight from '../../icons/ChevronRight';
import {nl} from 'date-fns/locale';
import CSS from './ReservationDetailsDense.module.css';
import {ErrorMessage} from '../../helper/errorMapper';

type ReservationDetailsDenseProps = {
    showExtras?: boolean;
    errors?: ErrorMessage[];
}

const ReservationDetailsDense = ({showExtras = false, errors}: ReservationDetailsDenseProps) => {
    const reservation = useAppSelector(selectReservation);
    const [expanded, setExpanded] = useState(false);
    const {t} = useTranslation();
    const {
        data: chef,
        isLoading,
        isError,
        error
    } = useGetChefByIdQuery({
        chefId: reservation.chefId,
        chefLabel: reservation.chefLabel,
    });

    useEffect(() => {
        if (errors && errors.length === 0)
            return;

        setExpanded(true);
    }, [errors]);

    if (isLoading)
        return null;

    if (isError)
        return <span className={'text-red'}>{error}</span>;

    const chosenListing = chef?.listings.find(listing => listing.id === reservation.listingId);

    const detailsToString = (reservation: ReservationState) => {
        const stringParts = [];

        if (reservation.date) {
            stringParts.push(format(reservation.date, 'PPPP', {locale: nl}));
        }

        if (reservation.time) {
            stringParts.push(reservation.time);
        }

        if (reservation.numberOfPeople) {
            stringParts.push(t('General.PeopleCount', {numberOfPeople: reservation.numberOfPeople}));
        }

        return stringParts.join(' | ');
    };

    const reservationString = detailsToString(reservation);

    const onExpanderClick = () => {
        setExpanded(expanded => !expanded);
    };

    return (
        <div className={CSS.Container}>
            <div>
                <button className={CSS.ExpandButton} onClick={onExpanderClick}>
                    <span className={CSS.ExpandButtonText}>{reservationString}</span>
                    {expanded ? <ChevronDown className={CSS.ExpandIcon}/> :
                        <ChevronRight className={CSS.ExpandIcon}/>}
                </button>
                <Transition
                    show={expanded}
                    className={CSS.PanelWrapper}
                    enter="transition-opacity duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150 ease-out"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <ReservationDetails errors={errors} enforceListingRestrictions/>
                </Transition>
            </div>
            {showExtras && chosenListing &&
                <div className={CSS.ExtrasContainer}>
                    <ExtrasInput options={chosenListing?.extras}/>
                </div>
            }
        </div>
    );
};

export default ReservationDetailsDense;
