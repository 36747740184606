import React from 'react';
import { Extra } from '../../types/Extra';
import CounterInput from '../CounterInput';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { changeExtra, selectReservation } from '../../features/reservation/reservationSlice';
import { useTranslation } from 'react-i18next';
import CSS from './ExtraPerGuest.module.css';

type ExtraPerGuestProps = {
  extra: Extra;
}

const ExtraPerGuest = ({ extra }: ExtraPerGuestProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const reservation = useAppSelector(selectReservation);
  const value = reservation?.extras?.find(f => f.extraId === extra.id)?.amount ?? 0;

  const onIncrement = () => {
    dispatch(changeExtra({ extra, amount: value + 1 }));
  };
  const onDecrement = () => {
    if (value <= 0) {
      dispatch(changeExtra({ extra, amount: 0 }));
    } else {
      dispatch(changeExtra({ extra, amount: value - 1 }));
    }
  };
  const onChange = (value: number) => {
    if (value < 0) {
      dispatch(changeExtra({ extra, amount: 0 }));
    } else {
      dispatch(changeExtra({ extra, amount: value }));
    }
  };

  return (
    <div className={CSS.Container}>
      <div className={CSS.LabelContainer}>
        <span className={CSS.Label}>
          {extra.title}
          <span className={CSS.SubLabel}>
            {' ' + t('Listing.ExtrasPricePerPerson', { amount: extra.price })}
          </span>
        </span>
        <span className={CSS.Description}>{extra.description}</span>
      </div>
      <div className={CSS.InputContainer}>
        <CounterInput
          value={value}
          min={0}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default ExtraPerGuest;