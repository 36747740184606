import React from 'react';
import { Listing } from '../../types/Listing';
import Slider from '../Slider';
import CSS from './ListingImageHeader.module.css';

type ListingImageHeaderProps = {
  listing: Listing;
}

const ListingImageHeader = ({ listing }: ListingImageHeaderProps) => {
  return (
    <div className={CSS.Container}>
      <div className={CSS.TitleContainer}>
        <h1 className={CSS.Title}>{listing.title}</h1>
      </div>
      <Slider>
        {listing.images.map((img) => (
          <img
            key={img?.url}
            className={CSS.Image}
            alt={img?.title}
            src={img?.url}
          />
        ))}
      </Slider>
    </div>
  );
};

export default ListingImageHeader;