import React, { ChangeEventHandler } from 'react';
import CSS from './TimeInput.module.css';

const hours = [
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
];

const slots = hours
  .reduce<string[]>((slots, hour) => {
  slots.push(`${hour}:00`);
  slots.push(`${hour}:15`);
  slots.push(`${hour}:30`);
  slots.push(`${hour}:45`);
  return slots;
}, [])
  .concat('21:00');

type TimeInputProps = {
  value: string | null;
  onChange: (val: string) => void;
}

const TimeInput = ({ value, onChange }: TimeInputProps) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <div className={CSS.Container}>
      <select onChange={handleChange} className={CSS.Select} value={value ?? ''}>
        {slots.map((slot) => (
          <option key={slot} value={slot}>
            {slot}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeInput;