import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  getHouseNumber,
  getCountryOfResidence,
  getPlaceOfResidence,
  getPostalCode,
  getStreet,
} from '../../helper/googlePlaces';

export interface DetailsState {
  receiveInvoice: boolean;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  notes: string;
  location: {
    formattedAddress: string;
    addressComponents: any;
    street: string;
    annex: string;
    houseNumber: string;
    placeOfResidence: string;
    countryOfResidence: string;
    postCode: string;
  };
  companyLocation: {
    street: string;
    annex: string;
    houseNumber: string;
    placeOfResidence: string;
    countryOfResidence: string;
    postCode: string;
  };
  sameBillingAddress: boolean;
}

export const initialState: DetailsState = {
  receiveInvoice: false,
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  notes: '',
  location: {
    formattedAddress: '',
    addressComponents: {},
    street: '',
    annex: '',
    houseNumber: '',
    countryOfResidence: '',
    placeOfResidence: '',
    postCode: '',
  },
  companyLocation: {
    street: '',
    annex: '',
    houseNumber: '',
    countryOfResidence: '',
    placeOfResidence: '',
    postCode: '',
  },
  sameBillingAddress: true,
};

export const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    setDetailsField: (state, action: PayloadAction<{ fieldName: string; value: any }>) => {
      const { fieldName, value } = action.payload;
      return {
        ...state,
        [fieldName]: value
      };
    },
    setDetailsLocationField: (state, action: PayloadAction<{ fieldName: string; value: string }>) => {
      const { fieldName, value } = action.payload;

      return {
        ...state,
        location: {
          ...state.location,
          [fieldName]: value
        }
      };
    },
    setDetailsCompanyLocationField: (state, action: PayloadAction<{ fieldName: string; value: string }>) => {
      const { fieldName, value } = action.payload;

      return {
        ...state,
        companyLocation: {
          ...state.companyLocation,
          [fieldName]: value
        }
      };
    },
    setLocation: (state, action: PayloadAction<{ formattedAddress: string; addressComponents: any[] }>) => {
      state.location.formattedAddress = action.payload.formattedAddress;
      state.location.addressComponents = action.payload.addressComponents;
      state.location.street = getStreet(action.payload.addressComponents);
      state.location.houseNumber = getHouseNumber(action.payload.addressComponents);
      state.location.countryOfResidence = getCountryOfResidence(action.payload.addressComponents);
      state.location.placeOfResidence = getPlaceOfResidence(action.payload.addressComponents);
      state.location.postCode = getPostalCode(action.payload.addressComponents);
    },
    toggleSameBillingAddress: (state) => {
      state.sameBillingAddress = !state.sameBillingAddress;
    },
  },
});

export const {
  setLocation,
  setDetailsField,
  setDetailsLocationField,
  setDetailsCompanyLocationField,
  toggleSameBillingAddress,
} = detailsSlice.actions;

export const selectIsCompany = (state: RootState) => state.details.receiveInvoice;
export const selectLocationDetails = (isCompany?: boolean) => (state: RootState) => isCompany ? state.details.companyLocation : state.details.location;
export const selectDetails = (state: RootState) => state.details;

export default detailsSlice.reducer;
