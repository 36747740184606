import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {Extra} from '../../types/Extra';
import {Listing} from '../../types/Listing';
import {Chef} from '../../types/Chef';

export interface ReservationState {
    chefId: string | number;
    chefLabel: string | number | null;
    signature: string;
    date: null | number;
    time: string | null;
    numberOfPeople: null | number;
    maxNumberOfPeople: number;
    minNumberOfPeople: number;
    extras: { extraId: string; amount: number }[];
    listingId: null | number;
}

export const initialState: ReservationState = {
    chefId: '',
    chefLabel: '',
    signature: '',
    date: null,
    time: '19:00',
    numberOfPeople: 6,
    minNumberOfPeople: 1,
    maxNumberOfPeople: 9999,
    extras: [],
    listingId: null
};

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        setChefId: (state, action: PayloadAction<string>) => {
            if (!action.payload)
                return;

            state.chefId = action.payload;
        },
        setChefLabel: (state, action: PayloadAction<string>) => {
            if (!action.payload)
                return;

            state.chefLabel = action.payload;
        },
        setSignature: (state, action: PayloadAction<string>) => {
            if (!action.payload)
                return;

            state.signature = action.payload;
        },
        setChefDefaults: (state, action: PayloadAction<Chef>) => {
            // get min number of people out of all listings
            state.minNumberOfPeople = action.payload.listings.reduce((acc, curr) => {
                return acc < curr.min_guests ? acc : curr.min_guests;
            }, 2);
        },
        incrementNrOfPeople: (state) => {
            if (state.numberOfPeople === null) {
                state.numberOfPeople = 1;
            } else {
                state.numberOfPeople += 1;
            }
        },
        decrementNrOfPeople: (state) => {
            if (state.numberOfPeople === null) {
                return;
            }

            if (state.numberOfPeople === state.minNumberOfPeople) {
                state.listingId = null;
            }

            if (state.numberOfPeople > 0)
                state.numberOfPeople -= 1;
        },
        setNrOfPeople: (state, action: PayloadAction<number>) => {
            if (action.payload > state.maxNumberOfPeople) {
                state.numberOfPeople = state.maxNumberOfPeople;
            } else if (action.payload < state.minNumberOfPeople) {
                state.numberOfPeople = state.minNumberOfPeople;
            } else {
                state.numberOfPeople = action.payload;
            }
        },
        setDate: (state, action: PayloadAction<number | null>) => {
            state.date = action.payload;
        },
        setTime: (state, action: PayloadAction<string>) => {
            state.time = action.payload;
        },
        setListing: (state, action: PayloadAction<Listing>) => {
            const {id, min_guests, max_guests} = action.payload;
            if (state.numberOfPeople !== null && (state.numberOfPeople < min_guests || state.numberOfPeople > max_guests)) {
                return;
            }

            state.listingId = id;
        },
        changeExtra: (state, action: PayloadAction<{ extra: Extra; amount: number }>) => {
            const {extra, amount} = action.payload;

            const currentExtraIndex = state.extras.findIndex(f => f.extraId === extra.id);

            if (currentExtraIndex !== -1) {
                state.extras[currentExtraIndex] = {
                    ...state.extras[currentExtraIndex],
                    amount
                };
            } else {
                if (amount > 0) {
                    state.extras.push({extraId: extra.id, amount: amount});
                }
            }
        }
    }
});

export const {
    setChefId,
    setChefLabel,
    setSignature,
    setChefDefaults,
    incrementNrOfPeople,
    decrementNrOfPeople,
    setNrOfPeople,
    setDate,
    setTime,
    setListing,
    changeExtra
} = reservationSlice.actions;

export const selectReservation = (state: RootState) => state.reservation;

export default reservationSlice.reducer;
