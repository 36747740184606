import React, { HTMLProps, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper';
import ChevronRight from '../../icons/ChevronRight';
import { Swiper as SwiperClass } from 'swiper/types';
import ChevronLeft from '../../icons/ChevronLeft';
import clsx from 'clsx';
import CSS from './Slider.module.css';

type SliderProps = {
  children: React.ReactNode[];
}

const NavigationLeft = React.forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>((props, ref) => {
  return (
    <button ref={ref}
      type={'button'}
      className={clsx(CSS.NavigationButton, CSS.NavigationButtonLeft)}
      onClick={props.onClick}
    ><ChevronLeft/>
    </button>
  );
});

const NavigationRight = React.forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>((props, ref) => {
  return (
    <button ref={ref}
      type={'button'}
      disabled={props.disabled}
      className={clsx(CSS.NavigationButton, CSS.NavigationButtonRight)}
    >
      <ChevronRight/>
    </button>
  );
});

const Slider = ({ children }: SliderProps) => {
  const [swipeControl, setSwipeControl] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);

  return (
    <Swiper
      onSwiper={(swiper) => setSwipeControl(swiper)}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      className={CSS.Slider}
      modules={[Navigation]}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current
      }}
    >
      {React.Children.map(children, (child) => (
        <SwiperSlide>
          {child}
        </SwiperSlide>
      ))}
      <NavigationLeft ref={navigationPrevRef} disabled={activeIndex === 0}/>
      <NavigationRight ref={navigationNextRef} disabled={activeIndex === (swipeControl?.slides?.length || 0) - 1}/>
    </Swiper>
  );
};

export default Slider;
