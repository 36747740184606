import React, { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { eligibleCountries } from '../../helper/googlePlaces';
import InputWithLabel from '../InputWithLabel';
import SelectWithLabel from '../SelectWithLabel';
import CheckboxInput from '../CheckboxInput';
import {
  selectDetails,
  setDetailsCompanyLocationField,
  setDetailsLocationField,
  toggleSameBillingAddress,
  selectLocationDetails
} from '../../features/details/detailsSlice';
import CSS from './LocationFields.module.css';

type LocationFieldsProps = {
  isCompany?: boolean;
}
const LocationFields = ({ isCompany }: LocationFieldsProps) => {
  const { t } = useTranslation();
  const details = useAppSelector(selectDetails);
  const locationDetails = useAppSelector(selectLocationDetails(isCompany));
  const dispatch = useAppDispatch();

  const handleDetailsLocationChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    if (isCompany) {
      dispatch(setDetailsCompanyLocationField({ fieldName: e.currentTarget.name, value: e.currentTarget.value }));
    } else {
      dispatch(setDetailsLocationField({ fieldName: e.currentTarget.name, value: e.currentTarget.value }));
    }
  };

  const handleSameBillingAddressToggle = () => {
    dispatch(toggleSameBillingAddress());
  };

  return (
    <div>
      {isCompany &&
        <CheckboxInput
          name={'sameBillingAddress'}
          label={t('Reservation.SameBillingAddress')}
          checked={details.sameBillingAddress}
          onChange={handleSameBillingAddressToggle}
        />
      }
      {(!isCompany || (isCompany && !details.sameBillingAddress)) &&
        <div className={CSS.CompanyFields}>
          <InputWithLabel
            label={isCompany ? t('Reservation.Address.CompanyAddress') : t('Reservation.Address.Street')}
            inputProps={{
              id: 'street',
              name: 'street',
              type: 'text',
              autoComplete: 'street',
              required: true,
              className: CSS.Input,
              onChange: handleDetailsLocationChange as any,
              value: locationDetails?.street
            }}
          />
          <InputWithLabel
            label={t('Reservation.Address.HouseNumber')}
            inputProps={{
              id: 'houseNumber',
              name: 'houseNumber',
              type: 'text',
              autoComplete: 'houseNumber',
              required: true,
              className: CSS.Input,
              onChange: handleDetailsLocationChange as any,
              value: locationDetails?.houseNumber
            }}
          />
          <InputWithLabel
            label={t('Reservation.Address.Annex')}
            inputProps={{
              id: 'annex',
              name: 'annex',
              type: 'text',
              autoComplete: 'annex',
              required: false,
              className: CSS.Input,
              onChange: handleDetailsLocationChange as any,
              value: locationDetails?.annex
            }}
          />
          <InputWithLabel
            label={t('Reservation.Address.PostCode')}
            inputProps={{
              id: 'postCode',
              name: 'postCode',
              type: 'text',
              autoComplete: 'postCode',
              required: true,
              className: CSS.Input,
              onChange: handleDetailsLocationChange as any,
              value: locationDetails?.postCode
            }}
          />
          <InputWithLabel
            label={t('Reservation.Address.PlaceOfResidence')}
            inputProps={{
              id: 'placeOfResidence',
              name: 'placeOfResidence',
              type: 'text',
              autoComplete: 'placeOfResidence',
              required: true,
              className: CSS.Input,
              onChange: handleDetailsLocationChange as any,
              value: locationDetails?.placeOfResidence
            }}
          />
          <SelectWithLabel
            label={t('Reservation.Address.CountryOfResidence')}
            selectProps={{
              id: 'countryOfResidence',
              name: 'countryOfResidence',
              autoComplete: 'countryOfResidence',
              required: true,
              className: CSS.Input,
              onChange: handleDetailsLocationChange as any,
              value: locationDetails?.countryOfResidence
            }}
          >
            <option value={''}>{t('Reservation.Address.CountryOfResidence')}</option>
            {eligibleCountries.map((shortCode: string) =>(
              <option value={shortCode} key={shortCode}>{t(`Countries.${shortCode}`)}</option>
            ))}
          </SelectWithLabel>
        </div>
      }
    </div>
  );
};

export default LocationFields;
