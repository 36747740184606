import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { postWidgetMessage } from '../../helper/iframeInteraction';

export interface UIState {
  widgetOpen: boolean;
}

export const initialState: UIState = {
  widgetOpen: true
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setWidgetOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        postWidgetMessage({ type: 'show' });
      } else {
        postWidgetMessage({ type: 'hide' });
      }
    }
  }
});

export const {
  setWidgetOpen
} = uiSlice.actions;

export const selectWidgetOpen = (state: RootState) => state.ui.widgetOpen;

export default uiSlice.reducer;