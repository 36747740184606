import React, { ChangeEvent } from 'react';
import CSS from './CheckboxInput.module.css';

type CheckboxInputProps = {
  name: string;
  label: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxInput = ({ name, label, checked, onChange }: CheckboxInputProps) => {
  return (
    <div className={CSS.Container}>
      <input
        id={name}
        name={name}
        type={'checkbox'}
        checked={checked}
        onChange={onChange}
      />
      <label className={CSS.Label} htmlFor={name}>{label}</label>
    </div>
  );
};

export default CheckboxInput;