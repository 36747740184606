import React from 'react';
import CounterInput from '../CounterInput';
import TimeInput from '../TimeInput';
import DatePicker from 'react-datepicker';
import {parse} from 'date-fns';
import LocationInput from '../LocationInput';
import {
    decrementNrOfPeople,
    incrementNrOfPeople, selectReservation,
    setDate,
    setNrOfPeople,
    setTime,
} from '../../features/reservation/reservationSlice';
import {selectDetails, setLocation} from '../../features/details/detailsSlice';
import {useTranslation} from 'react-i18next';
import {useGetChefByIdQuery} from '../../features/chef/chefAPI';
import 'react-datepicker/dist/react-datepicker.css';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import DateInputButton from '../DateInputButton';
import InputWrapper from '../InputWrapper';
import {nl} from 'date-fns/locale';
import CSS from './ReservationDetails.module.css';
import {ErrorMessage} from '../../helper/errorMapper';

const referenceDate = new Date();

type ReservationDetailsProps = {
    errors?: ErrorMessage[];
    enforceListingRestrictions?: boolean; // do not allow people out of bounds, mainly
}

const ReservationDetails = ({errors, enforceListingRestrictions = false}: ReservationDetailsProps) => {
    const reservation = useAppSelector(selectReservation);
    const details = useAppSelector(selectDetails);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {
        data: chef,
        isLoading,
        isError,
        error,
    } = useGetChefByIdQuery({
        chefId: reservation.chefId,
        chefLabel: reservation.chefLabel,
    });

    const chosenListing = chef?.listings.find(listing => listing.id === reservation.listingId);

    if (isLoading)
        return null;

    if (isError)
        return <span className={'text-red'}>{error}</span>;


    const incrementPeople = () => {
        dispatch(incrementNrOfPeople());
    };
    const decrementPeople = () => {
        dispatch(decrementNrOfPeople());
    };

    const changeNrOfPeople = (newNrOfPeople: number) => {
        dispatch(setNrOfPeople(newNrOfPeople));
    };

    const handleDateChange = (newDate: Date) => {
        dispatch(setDate(newDate.getTime()));
    };

    const handleTimeChange = (newTime: string) => {
        dispatch(setTime(newTime));
    };

    const handleLocationChange = (newLocation: any) => {
        const {formatted_address: formattedAddress, address_components: addressComponents} = newLocation;
        dispatch(setLocation({formattedAddress, addressComponents}));
    };

    return (
        <div className={CSS.Container}>
            <InputWrapper>
                <label htmlFor="date" className={CSS.Label}>{t('Reservation.Date')}</label>
                <div className={CSS.DatePickerContainer}>
                    <DatePicker
                        required
                        locale={nl}
                        dateFormat={'PPPP'}
                        popperClassName={CSS.DatePickerPopper}
                        wrapperClassName={CSS.DatePickerWrapper}
                        minDate={referenceDate}
                        excludeDates={chef?.blocked_dates.map((dateString) => parse(dateString, 'yyyy-MM-dd', referenceDate) as Date) ?? []}
                        className={CSS.DatePicker}
                        selected={reservation.date ? new Date(reservation.date) : null}
                        onChange={handleDateChange}
                        customInput={<DateInputButton hasError={!!errors?.find(err => err.field === 'date')}/>}
                    />
                </div>
            </InputWrapper>
            <InputWrapper>
                <label htmlFor="time" className={CSS.Label}>{t('Reservation.Time')}</label>
                <TimeInput value={reservation.time} onChange={handleTimeChange}/>
            </InputWrapper>
            <InputWrapper>
                <label htmlFor="location" className={CSS.Label}>{t('Reservation.Location')}</label>
                <LocationInput
                    error={errors?.find(err => err.field === 'location')}
                    value={details.location.formattedAddress}
                    onChange={handleLocationChange}
                />
            </InputWrapper>
            <InputWrapper>
                <label className={CSS.Label}>{t('Reservation.NumberOfPeople')}</label>
                <CounterInput
                    min={(chosenListing && enforceListingRestrictions) ? chosenListing.min_guests : undefined}
                    max={(chosenListing && enforceListingRestrictions) ? chosenListing.max_guests : undefined}
                    onIncrement={incrementPeople}
                    onDecrement={decrementPeople}
                    onChange={changeNrOfPeople}
                    value={reservation.numberOfPeople}
                />
            </InputWrapper>
        </div>
    );
};

export default ReservationDetails;
